/* values 4 */
.corteromboclip {
  clip-path: polygon(50% 100%, 100% 75%, 100% 25%, 50% 0, 0 25%, 0 75%);
}

.corteromboclip2 {
  clip-path: polygon(
    50% 100%,
    100% 75%,
    100% 25%,
    50% 0,
    0 25%,
    0 75%,
    50% 100%,
    50% 98%,
    2% 74%,
    2% 26%,
    50% 2%,
    98% 26%,
    98% 74%,
    50% 98%
  );
}

.homi {
  clip-path: polygon(0 100%, 94% 100%, 100% 0, 0 0);
}

.corteHeaderbg {
  clip-path: polygon(0 0, 100% 0, 88% 100%, 0 100%);
}

.corteHeaderbg2 {
  clip-path: polygon(100% 0, 100% 100%, 12% 100%, 0 0);
}

.textcolor2 {
  color: var(--main-color);
}

.textcolor2:hover {
  color: var(--secondary-color);
}
